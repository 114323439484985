$(function () {
	toggleVideo();
	toggleHeader();
	popoERCode()
});
/**
 * header状态设置
 */
function toggleHeader() {
	var header = $("header");
	var percent
	if($(window).scrollTop()>800){
		percent = $(window).scrollTop() /800;
		headerState(percent)
	}
	$(window).scroll(function () {
		percent = $(window).scrollTop() /800;
		if(percent<1){
			headerState(percent)
		}
	})
	function headerState(percent) {
		header.find('.logo2').css({
			opacity:percent
		})
		header.css({
			background:'rgba(255,255,255,'+ percent +')'
		});
		$(".webTitle,.topNav a").css({
			color:'hsla(0, 0%, '+(1-percent)*100+'%,1)'
		})
	}
}

/**
 * 设置并切换首页背景视频
 */
function toggleVideo() {
	var videoDom = $("#BGVideo")[0];
	var srcArr = ["https://static001.youtoo365.com/web/video/Blurry-Lights.mp4","https://static001.youtoo365.com/web/video/Nowhere.mp4"];
	var srcLen= srcArr.length;
	var currVideo = 0;
	playNow();
	videoDom.addEventListener("ended",playNow);
	function playNow() {
		videoDom.src = srcArr[currVideo];
		$(videoDom).toggleClass("fadeIn fadeOut");
		hideVideo();
		currVideo++;
		if(currVideo>= srcLen){
			currVideo =0;
		}
	}
	function hideVideo() {
		videoDom.addEventListener("timeupdate",function () {
			var videoTime = videoDom.duration;
			var currentTime = videoDom.currentTime;
			if(videoTime - currentTime <= 1){
				$(videoDom).removeClass("fadeIn");
				$(videoDom).addClass("fadeOut");
				// console.log("我要隐藏啦！")
			}
		});
	}
}
/**
 * 弹出二维码
 */
function popoERCode() {
	$('.iconWeixin').hover(function () {
		$('.erCodeWeixin').show()
    },function () {
        $('.erCodeWeixin').hide()
    })
    $('.iconWeibo').hover(function () {
        $('.erCodeWeibo').show()
    },function () {
        $('.erCodeWeibo').hide()
    })
}